<template>
    <div>
      <job-reminder-criterion-list></job-reminder-criterion-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  